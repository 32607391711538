import "./styles.scss";
import { ServicesData } from "./constants";

const OurServices = () => {
  return (
    <div id="services">
      <div className="container" data-scroll-index="2">
        <div className="services-container">
          <div className="d-flex align-items-center justify-content-between">
            <div className="values-heading">Our Services</div>
          </div>
          <div className="values-subheading">
            Design, Build, Transform: Our Comprehensive Services
          </div>
          {ServicesData.map((item, index) => (
            <div className="row" key={index}>
              <div className={item.imageCol}>
                <img src={item.img} alt="farm" className="services-img" />
              </div>
              <div className={item.contentCol}>
                <div className="service-heading">{item.heading}</div>
                <div className="service-subtitle">{item.subtitle}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default OurServices;
