import { InteriorSolutionsHeading, InteriorSolutionsSubHeading, InteriorSolutionsList } from "./constant";
import MobileImg from "../../../assets/images/benefits/5steps-sideimage.webp";
const InteriorSolutions = () => {
  return (
    <div className="mt-5">
      <div className="container">
      <div className="iheading ">{InteriorSolutionsHeading}</div>
      <div className="isubheading">{InteriorSolutionsSubHeading}</div>
      <div className="imobile row align-items-center">
        <div className="col-md-12 col-lg-6 m-auto order-lg-1 order-2">
          {InteriorSolutionsList.map((item, index) => (
            <div className="ilist" key={index}>
              <img src={item.img} alt={item.title} />
              <div className="content">
                <div className="title">{item.title}</div>
                <div className="subtitle">{item.subtitle}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="col-md-12 col-lg-6 text-center order-lg-2 order-1">
          <img src={MobileImg} alt="mobile" className="mobile_img" />
        </div>
      </div>
    </div>
    </div>
  );
};
export default InteriorSolutions;
