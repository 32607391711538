// Slider
import DailyDelivery from "./images/DailyDelivery.webp";
import AllOrder from "./images/AllOrder.webp";
// Products
import civilWork from "../../../assets/images/benefits/civil-work.webp";
import interiorWork from "../../../assets/images/benefits/interior-work.webp";
import customisedFurniture from "../../../assets/images/benefits/customised-furniture.webp";
import fireFightingWork from "../../../assets/images/benefits/fire-fighting-work.webp";
import electricalWork from "../../../assets/images/benefits/electric-work.webp";
import hvacWork from "../../../assets/images/benefits/hvac-work.webp";
import lpg_pngWork from "../../../assets/images/benefits/lpg-png-work.webp";
import plumbingWork from "../../../assets/images/benefits/plumbing-work.webp";



// Benefits
import designer from "../../../assets/images/benefits/designer.webp";
import agreement from "../../../assets/images/benefits/agreement.webp";
import commencement from "../../../assets/images/benefits/commencement.webp";
import execution_installation from "../../../assets/images/benefits/execution-installation.webp";
import moving_in from "../../../assets/images/benefits/moving-in.webp";

// Slider
export type BenefitTypes = {
  id: number;
  name: string;
  content: string;
  icon: string;
}[];
export const benefitList: BenefitTypes = [
  {
    id: 0,
    name: "What We Believe",
    content:
      "Our core values shape our culture and define who we are. They underscore our commitment to our clients, to our communities and to each other, and provide direction for everything we do. We strongly believe in teamwork, ethics and excellence.",
    icon: DailyDelivery,
  },
  {
    id: 1,
    name: "Why Choose Us",
    content:
      "We aim to eliminate the task of dividing your project between different architecture and construction company. We are a firm that offers plan, design, and build services for you from initial sketches to the final construction. We take responsibility to do a best quality plan and create best quality construction work as per drawing schedule. We have always strived to provide value to our client money, ensuring that they attain the highest level of satisfaction. As The General Contractor, We first create the highest level of trust and integrity with our clients.",
    icon: AllOrder,
  }
];
// Products
export const WhatWeDoHeading = "What We Do";
export const WhatWeDoSubHeading =
  "Transforming Spaces with Innovative Design, Expert Project Management, and Exceptional Craftsmanship for Unforgettable Experiences";
export const WhatWeDoList = [
  {
    img: civilWork,
    txt: "Civil Work",
  },
  {
    img: interiorWork,
    txt: "Interior Work",
  },
  {
    img: customisedFurniture,
    txt: "Customised Furniture ",
  },
  {
    img: fireFightingWork,
    txt: "Fire Fighting Work",
  },
  {
    img: electricalWork,
    txt: "Electrical Work",
  },
  {
    img: hvacWork,
    txt: "HVAC Work",
  },
  {
    img: plumbingWork,
    txt: "Plumbing Work",
  },
  {
    img: lpg_pngWork,
    txt: "LPG/PNG Work",
  },
];
// Benefits
export const InteriorSolutionsHeading = "Complete Interior Solution in 5 Steps";
export const InteriorSolutionsSubHeading =
  "From Vision to Reality: Our Comprehensive 5-Step Interior Solution for Transforming Spaces";
export const InteriorSolutionsList = [
  {
    img: designer,
    title: "Meet Designer",
    subtitle:
      "Get free consultation from our expert design team to understand your requirement and preferences.",
  },
  {
    img: agreement,
    title: "Make an Agreement",
    subtitle:
      "When you are satisfied with our proposal, book our services by paying 10% of the estimated project cost.",
  },
  {
    img: commencement,
    title: "Work Commencement",
    subtitle:
      "Pay 40% to initiate the work as per the finalized design, and monitor the progress through regular updates.",
  },
  {
    img: execution_installation,
    title: "Execution & Installation",
    subtitle:
      "Pay 45% to before installation and see your dream home come to life.",
  },
  {
    img: moving_in,
    title: "Moving In",
    subtitle:
      "Pay remaining 5% before final handover and let the good mes roll. ",
  },
];
