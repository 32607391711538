import ArchitectureDesign from "../../../assets/images/our-values/architecture-design.webp";
import InteriorDesign from "../../../assets/images/our-values/interior-design.webp";
import WorkFlowManagement from "../../../assets/images/our-values/work-flow-management.webp";
import ProjectManagement from "../../../assets/images/our-values/project-management.webp";

export const ServicesData = [
    {
      heading: "Architecture Design",
      subtitle:
        "Where imagination meets functionality, shaping spaces with creativity, precision, and purpose, inspiring awe and enriching the human experience.",
      imageCol: "col-md-12 col-lg-6",
      contentCol: "col-md-12 col-lg-6 m-auto",
      img: ArchitectureDesign,
      showDownload: false,
    },
    {
      heading: "Interior Design: Transforming Spaces, Enriching Lives",
      subtitle:
        "Interior design is an artful blend of aesthetics, functionality, and ambiance, where spaces are meticulously curated to reflect individuality, inspire harmony, and elevate the human experience.",
      imageCol: "col-md-12 col-lg-6 m-auto order-lg-2 order-md-1",
      contentCol: "col-md-12 col-lg-6 order-lg-1 order-md-2 m-auto",
      img: InteriorDesign,
      showDownload: false,
    },
    {
      heading: "Efficient Work Flow Management: Streamlining Processes for Success",
      subtitle:
        "Effective work flow management is the key to optimizing productivity, minimizing bottlenecks, and achieving seamless collaboration within an organization. By implementing streamlined processes, leveraging technology, and fostering clear communication, work flow management empowers teams to achieve their goals with efficiency and excellence.",
      imageCol: "col-md-12 col-lg-6",
      contentCol: "col-md-12 col-lg-6 m-auto",
      img: WorkFlowManagement,
      showDownload: false,
    },
    {
      heading: "Project Management: Orchestrating Success through Strategic Execution",
      subtitle:
        "Project management is the art of guiding and coordinating every aspect of a project, from inception to completion. It involves meticulous planning, effective communication, resource allocation, and risk management to ensure successful outcomes. With skilled project management, organizations can navigate complexities, meet deadlines, and deliver exceptional results.",
      imageCol: "col-md-12 col-lg-6 m-auto order-lg-2 order-md-1",
      contentCol: "col-md-12 col-lg-6 order-lg-1 order-md-2 m-auto",
      img: ProjectManagement,
      showDownload: true,
    },
  ];