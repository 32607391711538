import logo from '../../assets/images/common/logo.png';
import Location from '../../assets/images/footer/location.svg';
import Phone from '../../assets/images/footer/phone.svg';
import Email from '../../assets/images/footer/email.svg';

export const WEB_LOGO = logo;

export const FollowTxt = 'Follow us on:';
export const Copyright = `Copyright © ${new Date().getFullYear()}  Future Design & Infra Pvt. Ltd. - All Rights Reserved.`;
export const FacebookLink = 'https://www.facebook.com/justfreshproduceapp';
export const ContactInfo = [
  {
    src: Phone,
    name:'Phone',
    to: 'tel:8287722607',
    txt: 'Contact Number: 8287722607, 8287082885',
  },
  { src: Email, to: 'mailto:futuredesignandinfra@gmail.com', txt: 'futuredesignandinfra@gmail.com',name:'Email' },
  {
    src: Location,
    to: 'https://www.google.com/maps/place/Garhwali+mohalla/@28.636087,77.2706049,16z/data=!4m10!1m2!2m1!1sHouse+No.+10,+Street+No.+1,+Garhwali+Mohalla,+Laxmi+Nagar,+Delhi-110092!3m6!1s0x390cfdc773626219:0x4ab8aef63a066acc!8m2!3d28.6328529!4d77.2737222!15sCkdIb3VzZSBOby4gMTAsIFN0cmVldCBOby4gMSwgR2FyaHdhbGkgTW9oYWxsYSwgTGF4bWkgTmFnYXIsIERlbGhpLTExMDA5MpIBD2hvdXNpbmdfY29tcGxleOABAA!16s%2Fg%2F11rvh83r5j?entry=ttu2',
    txt: 'Block-I House No. 10, Street No. 1, Garhwali Mohalla, Laxmi Nagar, Delhi-110092',
    name:'Location'
  },
];
