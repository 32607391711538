import { Link } from 'react-router-dom';
import './styles.scss';
import empty from '../../assets/images/common/empty.webp';

const NotFound = () => (
  <div className='container-fluid not-found-wrapper text-center my-5'>
    <img src={empty} alt='Not Found' />
    <h1>404</h1>
    <h2>Page Not Found</h2>
    <Link className='navBtnLink home' to='/'>
      GO HOME
    </Link>
  </div>
);
export default NotFound;
