import "./styles.scss";
import Menu_Icon from "../../assets/images/common/menu.webp";
import Cross_Icon from "../../assets/images/common/cross.webp";
import LOGO from "../../assets/images/common/logo.png";
import cx from "classnames";
import useNavbar from "./useNavbar";
import { Link } from "react-router-dom";

const Navbar = () => {
  const { toggle, setToggle, scroll } = useNavbar();
  return (
    <nav className={cx("navbar navbar-expand-md", { "box-shadow": scroll })}>
      <div className="w-100 mx-3">
        <div className="w-100 d-flex justify-content-between align-items-center w-100">
          <div className="d-flex justify-content-between align-items-center w-100">
            <Link to="/" className="nav-link logo p-0" onClick={() => window.scrollTo(0, 0)}>
              <img src={LOGO} alt="logo" className="logo" />
            </Link>
            <ul
              className={cx(
                "align-items-center m-0 navbar-nav",
                {
                  active: toggle,
                }
              )}
            >
              <li
                className="nav-item cross_icon"
                onClick={() => setToggle(!toggle)}
              >
                <img src={Cross_Icon} alt="cross_icon" className="cross_icon" />
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  data-scroll-nav="1"
                  onClick={() => setToggle(!toggle)}
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  data-scroll-nav="2"
                  onClick={() => setToggle(!toggle)}
                >
                  Our Values
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  data-scroll-nav="3"
                  onClick={() => setToggle(!toggle)}
                >
                  What We Do
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  data-scroll-nav="4"
                  onClick={() => setToggle(!toggle)}
                >
                  Testimonials
                </Link>
              </li> */}
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  data-scroll-nav="5"
                  onClick={() => setToggle(!toggle)}
                >
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
          <img
            src={Menu_Icon}
            alt="menu_icon"
            className="menu_icon"
            onClick={() => setToggle(!toggle)}
          />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
